import React, { useState } from "react";
import "./HomePage.css";
import style from "../App.module.css";
import vdo from "../assets/backgroundsite.mp4";

import Navbar from "./Navbar";
import { Modal } from "flowbite-react";
import { useImage, Img } from "react-image";
import Swal from "sweetalert2/dist/sweetalert2.js";
let link_launcher =
	"https://cdn.discordapp.com/attachments/1163949203635056642/1171946949386195025/BrasilMobileRoleplay.apk";
let link_tutorial = "https://www.youtube.com/watch?v=xJddp0Hpoo4";
const HomePage = () => {
	const [openModal, setOpenModal] = useState(true);
	function handClick(event) {
		event.preventDefault();
		Swal.fire({
			title: "Faça o Pagamento!",

			icon: "info",
			html: `<div style="width: 100%; user-select: text; font-family: 'Poppins'; height: 100%; display: flex; justify-content: center; flex-direction: column; text-align: center;">
                <h1 style="margin-top: 5px;">Via PIX<br>Chave PIX: <span style="color: orange;" onclick="copiarChave()" id="chavePix" >contatobmrp@gmail.com <br/>(Clique para Copiar)</span></h1> <h2 style="margin-top: 5px;">ou com outras formas de pagamento pelo site: <b><a style="color: blue;" href="https://link.mercadopago.com.br/bmrp" target="_blank" >https://link.mercadopago.com.br/bmrp </a> </b></h2>
                <p style="width: 100%; margin-top: 20px;">PRA RECEBER OS BENEFICIOS, É SÓ FAZER O PAGAMENTO E NOS ENVIAR O COMPROVANTE E SE VOCE JOGA NO SERVIDOR [1] OU [2]</p>
<h3 style="width: 100%; margin-top: 20px; text-decoration: none; font-weight: 'bold';">Veja como ativar os Benefícios: <a href="https://www.youtube.com/watch?v=0YNDdgS3_Lw" style="color: blue;">https://www.youtube.com/watch?v=0YNDdgS3_Lw</a></h3>
                <div style="width: 100%; margin-top: 5px; display: flex; justify-content: center;"><a style="padding-left: 15px; padding-right: 15px; padding-top: 8px; padding-bottom: 8px; background: green; width: 100%; color: #fff; border-radius: 10px; margin-top: 15px; font-size: 10pt;" href="https://api.whatsapp.com/send?phone=5577999618331&text=Ola,%20efetuei%20o%20pagamento%20da%20Promo%C3%A7%C3%A3o%20de%20ano%20novo%20e%20quero%20ativar%20os%20Beneficios!" target="_blank" >Enviar Comprovante</a></div>
            </div>`,
			showConfirmButton: false,
		});
	}
	return (
		<>
			<div className="overflow-hidden ">
				<div
					className="w-screen bg-slate-900 h-screen flex justify-center items-center"
					style={{ fontFamily: "Poppins" }}
				>
					<main className="w-screen relative h-screen mt-[9rem] bg-black flex justify-center mb-16">
						<div
							className="w-screen h-screen mb-12 absolute flex justify-center items-center"
							style={{
								zIndex: 55,
								background: "rgba(0,0,0,0.5)",
							}}
							onClick={() => setOpenModal(false)}
						>
							<div
								className="flex justify-center anim-scale-reverse items-center text-white"
								style={{ fontFamily: "Poppins", zIndex: 56 }}
							>
								<div className="w-[98%] md:w-[40%]  flex justify-center items-center text-white">
									<a
										href=""
										className="flex justify-center items-center"
										onClick={handClick}
									>
										<h1 className="absolute top-0 md:top-4 text-xl animate-none">
											Tem Promoção rolando!
										</h1>
										<p className="absolute top-1 md:top-4 mt-3 animate-none">
											Clique na foto para comprar
											essa Promoção!
										</p>
										<img
											src="https://i.imgur.com/pSHkYOU.jpg"
											className="rounded-lg"
											width="90%"
											height="90%"
										/>
									</a>
								</div>
							</div>
						</div>
					</main>
				</div>
			</div>
		</>
	);
};

export default HomePage;
