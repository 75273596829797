"use client";
import React from "react";
// import "./Catalogo.css";
import { Modal } from "flowbite-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/dist/sweetalert2.min.css";
import { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { VisibilityObserver, useVisibility } from "reactjs-visibility";
const Catalogo = (props) => {
	const { ref, visible } = useVisibility();
	useEffect(() => {
		if (visible) {
			// alert("I am now visible");
		}
	}, [visible]);
	const movieId = props;
	console.log(movieId);
	const [openModal, setOpenModal] = useState(false);
	const [modalInfo, setModalInfo] = useState({});

	return (
		<>
			{!visible && (
				<div className="loader-div">
					<span className="loader">
						<span></span>
						<span></span>
					</span>
				</div>
			)}
			<Navbar type="2" />

			<div
				ref={ref}
				className="w-screen h-[550vh] md:h-[220vh] overflow-y-auto flex justify-center items-center bg-slate-950 bg-no-repeat bg-cover"
				style={{ fontFamily: "Poppins" }}
				id="catalogo"
			>
				<div className="w-full absolute top-0 flex justify-center flex-col items-center mt-6  text-center">
					<h1 className="w-full text-white text-2xl">Catálogo</h1>
				</div>

				<main className="w-screen relative h-full overflow-y-auto flex justify-center pt-[5rem] ">
					<main
						className="w-full h-full flex justify-center relative overflow-y-auto"
						style={{ fontFamily: "Poppins" }}
					>
						<div className="container-fluid mt-3">
							<div className="container-fluid px-4 text-center mb-3">
								<div className="row gx-2">
									<div
										className="col-md-6 col-xl-3 mt-3 flex justify-center anim-scale-left"
										style={{ animationDelay: "0.5s" }}
									>
										<div className="w-full pt-[14rem] pb-[10rem] rounded-md bg-slate-900 relative">
											<div className="absolute mt-3 w-full px-3 top-0 text-center text-white">
												<h1 className="text-sm">
													VIP Ouro
												</h1>
											</div>
											<div className="absolute mt-3 w-full bottom-6 text-center text-white">
												<button
													className="bg-[#3b13d1] hover:bg-indigo-600 rounded-md px-12 py-2 z-10 relative"
													onClick={() => {
														setOpenModal(true);
														setModalInfo({
															titulo: "VIP Ouro",
															imgSrc: "https://i.imgur.com/FtCqQ23.png",
															descricao: (
																<p>
																	<ol>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			a
																			uma
																			skin
																			exclusiva
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			a um
																			bate-papo
																			exclusivo
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Colete
																			e
																			Munições
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Conserte
																			seu
																			veículo
																			em
																			qualquer
																			lugar
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Tenha
																			acesso
																			a
																			carros
																			exclusivos
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			a
																			NRG-500
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			a
																			casas
																			exclusivas
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;SMS
																			gratuito
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Salário
																			vip
																			ouro:
																			R$
																			3.000
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Area
																			vip
																			exclusiva
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			ao
																			/lockpick
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			a
																			veículos
																			de
																			controle
																			remoto
																			(drone
																			e
																			carrinho)
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			a
																			uma
																			tag
																			exclusiva
																			@VIP
																			OURO
																			⭝{" "}
																		</li>
																	</ol>
																</p>
															),
															precos: (
																<ul>
																	<li className="">
																		VIP{" "}
																		<b className="text-yellow-400">
																			Ouro
																		</b>
																		:{" "}
																		<span className="text-green-400">
																			15.000
																			CASH
																		</span>
																	</li>
																</ul>
															),
														});
													}}
												>
													Ver Benefícios
												</button>
											</div>
											<div className="w-full h-[80%] absolute top-[10%] text-center">
												<div className="w-full h-[80%] flex justify-center mt-3">
													<img
														src="https://i.imgur.com/FtCqQ23.png"
														className="d-block w-[70%] md:w-[65%] lg:w-[50%] xl:w-[80%] rounded-md"
														alt="..."
													/>
												</div>
											</div>
										</div>
									</div>
									<div
										className="col-md-6 col-xl-3 mt-3 flex justify-center anim-scale-left"
										style={{ animationDelay: "0.6s" }}
									>
										<div className="w-full pt-[14rem] pb-[10rem] rounded-md bg-slate-900 relative">
											<div className="absolute mt-3 w-full px-3 top-0 text-center text-white">
												<h1 className="text-sm">
													VIP Sócio
												</h1>
											</div>
											<div className="absolute mt-3 w-full bottom-6 text-center text-white overflow-y-visible">
												<button
													className="bg-[#3b13d1] hover:bg-indigo-600 rounded-md px-12 py-2 z-10 relative"
													onClick={() => {
														setOpenModal(true);
														setModalInfo({
															titulo: "VIP Sócio",
															imgSrc: "https://i.imgur.com/f3TH9Fv.png",
															descricao: (
																<p>
																	<ol className="h-[378px] overflow-y-auto">
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			a
																			uma
																			skin
																			exclusiva
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			a um
																			bate-papo
																			exclusivo
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Colete
																			e
																			Munições
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Conserte
																			seu
																			veículo
																			em
																			qualquer
																			lugar
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Abasteça
																			seu
																			veículo
																			em
																			qualquer
																			lugar
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Tenha
																			acesso
																			a
																			carros
																			exclusivos
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			a
																			NRG-500
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			a
																			JBL
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			a
																			casas
																			exclusivas
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Pode
																			ter
																			3
																			veículos
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Pode
																			ter
																			3
																			casas
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;SMS
																			gratuito
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Salário
																			vip
																			sócio:
																			R$
																			5,000
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Ao
																			ativar
																			você
																			receberá
																			R$
																			250,000
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Ao
																			ativar
																			você
																			receberá
																			5
																			níveis
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			ao
																			/camaleao
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			ao
																			/blindarveiculo
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			ao
																			/jetpack
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Você
																			poderá
																			pintar
																			seu
																			veículo
																			em
																			qualquer
																			local
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Area
																			vip
																			exclusiva
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			ao
																			/lockpick
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			a
																			veículos
																			de
																			controle
																			remoto
																			(tank,
																			aviãozinho,
																			drone
																			e
																			carrinho){" "}
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			a
																			uma
																			tag
																			exclusiva
																			@SÓCIO
																			💎{" "}
																		</li>
																	</ol>
																</p>
															),
															precos: (
																<ul>
																	<li className="">
																		VIP{" "}
																		<b className="text-blue-500">
																			Socio
																		</b>
																		:{" "}
																		<span className="text-green-400">
																			30.000
																			CASH
																		</span>
																	</li>
																</ul>
															),
														});
													}}
												>
													Ver Benefícios
												</button>
											</div>
											<div className="w-full h-[80%] absolute top-[10%] text-center">
												<div className="w-full h-[80%] flex justify-center mt-3">
													<img
														src="https://i.imgur.com/f3TH9Fv.png"
														className="d-block w-[70%] md:w-[65%] lg:w-[50%] xl:w-[80%] rounded-md"
														alt="..."
													/>
												</div>
											</div>
										</div>
									</div>
									<div
										className="col-md-6 col-xl-3 mt-3 flex justify-center anim-scale-left"
										style={{ animationDelay: "0.7s" }}
									>
										<div className="w-full pt-[14rem] pb-[10rem] rounded-md bg-slate-900 relative">
											<div className="absolute mt-3 w-full px-3 top-0 text-center text-white">
												<h1 className="text-sm">
													VIP Patrocinador
												</h1>
											</div>
											<div className="absolute mt-3 w-full bottom-6 text-center text-white overflow-y-visible">
												<button
													className="bg-[#3b13d1] hover:bg-indigo-600 rounded-md px-12 py-2 z-10 relative"
													onClick={() => {
														setOpenModal(true);
														setModalInfo({
															titulo: "VIP Patrocinador",
															imgSrc: "https://i.imgur.com/eJ4Ki1g.png",
															descricao: (
																<p>
																	<ol className="h-[378px] overflow-y-auto">
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			a
																			uma
																			skin
																			exclusiva
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			a um
																			bate-papo
																			exclusivo
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Colete
																			e
																			Munições
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Conserte
																			seu
																			veículo
																			em
																			qualquer
																			lugar
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Abasteça
																			seu
																			veículo
																			em
																			qualquer
																			lugar
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Tenha
																			acesso
																			a
																			carros
																			exclusivos
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			a
																			NRG-500
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			a
																			JBL
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			a
																			casas
																			exclusivas
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Pode
																			ter
																			4
																			veículos
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Pode
																			ter
																			4
																			casas
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;SMS
																			gratuito
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Salário
																			vip
																			patrocinador:
																			R$
																			7,500
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Ao
																			ativar
																			você
																			receberá
																			R$
																			300,000
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Ao
																			ativar
																			você
																			receberá
																			10
																			níveis
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			ao{" "}
																			<span className="text-indigo-800">
																				/camaleao
																			</span>
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			ao{" "}
																			<span className="text-indigo-800">
																				/blindarveiculo
																			</span>
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			ao{" "}
																			<span className="text-indigo-800">
																				/jetpack
																			</span>
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			ao{" "}
																			<span className="text-indigo-800">
																				/objvip
																			</span>
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			ao{" "}
																			<span className="text-indigo-800">
																				/tunar
																			</span>
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Você
																			poderá
																			pintar
																			seu
																			veículo
																			em
																			qualquer
																			local
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Area
																			vip
																			exclusiva
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			ao{" "}
																			<span className="text-indigo-800">
																				/lockpick
																			</span>
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Seu
																			veículo
																			não
																			poderá
																			ser
																			roubado
																			por
																			uma
																			lockpick
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			a
																			veículos
																			de
																			controle
																			remoto
																			(tank,
																			aviãozinho,
																			drone
																			e
																			carrinho)
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Acesso
																			a
																			uma
																			tag
																			exclusiva
																			@PATROCINADOR
																			💰{" "}
																		</li>
																	</ol>
																</p>
															),
															precos: (
																<ul>
																	<li className="">
																		VIP{" "}
																		<b className="text-indigo-800">
																			Patrocinador
																		</b>
																		:{" "}
																		<span className="text-green-400">
																			50.000
																			CASH
																		</span>
																	</li>
																</ul>
															),
														});
													}}
												>
													Ver Benefícios
												</button>
											</div>
											<div className="w-full h-[80%] absolute top-[10%] text-center">
												<div className="w-full h-[80%] flex justify-center mt-3">
													<img
														src="https://i.imgur.com/eJ4Ki1g.png"
														className="d-block w-[70%] md:w-[65%] lg:w-[50%] xl:w-[80%] rounded-md"
														alt="..."
													/>
												</div>
											</div>
										</div>
									</div>
									<div
										className="col-md-6 col-xl-3 mt-3 flex justify-center anim-scale-left"
										style={{ animationDelay: "0.8s" }}
									>
										<div className="w-full pt-[14rem] pb-[10rem] rounded-md bg-slate-900 relative">
											<div className="absolute mt-3 w-full px-3 top-0 text-center text-white">
												<h1 className="text-sm">
													Pacotes de Dinheiro
												</h1>
											</div>
											<div className="absolute mt-3 w-full bottom-6 text-center text-white">
												<button
													className="bg-[#3b13d1] hover:bg-indigo-600 rounded-md px-12 py-2  z-10 relative"
													onClick={() => {
														setOpenModal(true);
														setModalInfo({
															titulo: "Pacotes de Dinheiro",
															imgSrc: "https://i.imgur.com/f6VhNae.jpg",
															descricao: (
																<p>
																	Consiga
																	dinheiro
																	facilmente!
																	Compre
																	pacotes de
																	dinheiro e
																	fique
																	totalmente{" "}
																	<b>RICO!</b>
																</p>
															),
															precos: (
																<>
																	<h3 className="w-full">
																		Pacotes
																		de
																		Dinheiro:
																	</h3>
																	<ul>
																		<li className="text-green-700">
																			$500.000
																			:{" "}
																			<span className="text-green-400">
																				15.000
																				CASH
																			</span>
																		</li>

																		<li className="text-green-700">
																			$750.000
																			:{" "}
																			<span className="text-green-400">
																				20.000
																				CASH
																			</span>
																		</li>
																		<li className="text-green-700">
																			$1.000.000
																			:{" "}
																			<span className="text-green-400">
																				30.000
																				CASH
																			</span>
																		</li>
																	</ul>
																	<div className="w-full mt-3"></div>
																</>
															),
														});
													}}
												>
													Ver Benefícios
												</button>
											</div>
											<div className="w-full h-[80%] absolute top-[10%] text-center">
												<div className="w-full h-[80%] flex justify-center mt-3">
													<img
														src="https://i.imgur.com/f6VhNae.jpg"
														className="d-block w-[70%] md:w-[50%] lg:w-[50%] xl:w-[80%] rounded-md"
														alt="..."
													/>
												</div>
											</div>
										</div>
									</div>
									<div
										className="col-md-6 col-xl-3 mt-3 flex justify-center anim-scale-left"
										style={{ animationDelay: "0.9s" }}
									>
										<div className="w-full pt-[14rem] pb-[10rem] rounded-md bg-slate-900 relative">
											<div className="absolute mt-3 w-full px-3 top-0 text-center text-white">
												<h1 className="text-sm">
													Pacotes de Niveis
												</h1>
											</div>
											<div className="absolute mt-3 w-full bottom-6 text-center text-white">
												<button
													className="bg-[#3b13d1] hover:bg-indigo-600 rounded-md px-12 py-2  z-10 relative"
													onClick={() => {
														setOpenModal(true);
														setModalInfo({
															titulo: "Pacotes de Níveis",
															imgSrc: "https://i.imgur.com/AHEKV5L.jpg",
															descricao: (
																<p>
																	Consiga
																	Nível no
																	jogo e
																	libere
																	diversos
																	empregos,
																	entrada em
																	organizações
																	e claro,{" "}
																	<b className="font-bold">
																		Destaque!
																	</b>
																</p>
															),
															precos: (
																<>
																	<h3 className="w-full">
																		Pacotes
																		de
																		Níveis:
																	</h3>
																	<ul>
																		<li className="text-yellow-200">
																			15
																			Níveis
																			:{" "}
																			<span className="text-green-400">
																				15.000
																				CASH
																			</span>
																		</li>

																		<li className="text-yellow-200">
																			25
																			Níveis
																			:{" "}
																			<span className="text-green-400">
																				20.000
																				CASH
																			</span>
																		</li>
																		<li className="text-yellow-200">
																			35
																			Níveis
																			:{" "}
																			<span className="text-green-400">
																				30.000
																				CASH
																			</span>
																		</li>
																	</ul>
																</>
															),
														});
													}}
												>
													Ver Benefícios
												</button>
											</div>
											<div className="w-full h-[80%] absolute top-[10%] text-center">
												<div className="w-full h-[80%] flex justify-center mt-3">
													<img
														src="https://i.imgur.com/AHEKV5L.jpg"
														className="d-block w-[70%] md:w-[50%] lg:w-[50%] xl:w-[80%] rounded-md"
														alt="..."
													/>
												</div>
											</div>
										</div>
									</div>
									<div
										className="col-md-6 col-xl-3 mt-3 flex justify-center anim-scale-left"
										style={{ animationDelay: "1s" }}
									>
										<div className="w-full pt-[14rem] pb-[10rem] rounded-md bg-slate-900 relative">
											<div className="absolute mt-3 w-full top-0 text-center text-white">
												<h1 className="text-sm">
													Carros Raros
												</h1>
											</div>
											<div className="absolute mt-3 w-full bottom-6 text-center text-white">
												<button
													className="bg-[#3b13d1] hover:bg-indigo-600 rounded-md px-12 py-2  z-10 relative"
													onClick={() => {
														setOpenModal(true);
														setModalInfo({
															titulo: "Veiculos Raros",
															imgSrc: "https://i.imgur.com/qSGfHq4.jpg",
															descricao: (
																<p>
																	Viaje pela
																	cidade com
																	seu próprio
																	veiculo
																	RARO!{" "}
																</p>
															),
															precos: (
																<>
																	<div className="w-full text-start">
																		<h3 className="w-full">
																			Veiculos
																			Raros:
																		</h3>
																		<ul>
																			<li className="text-indigo-500">
																				Hotring:{" "}
																				<span className="text-green-400">
																					70.000
																					CASH
																				</span>
																			</li>
																			<li className="text-indigo-500">
																				Sandking:{" "}
																				<span className="text-green-400">
																					60.000
																					CASH
																				</span>
																			</li>
																			<li className="text-indigo-500">
																				Roadtrain:{" "}
																				<span className="text-green-400">
																					50.000
																					CASH
																				</span>
																			</li>
																			<li className="text-indigo-500">
																				Bullet:{" "}
																				<span className="text-green-400">
																					80.000
																					CASH
																				</span>
																			</li>
																			<li className="text-indigo-500">
																				Phoenix:{" "}
																				<span className="text-green-400">
																					30.000
																					CASH
																				</span>
																			</li>
																			<li className="text-indigo-500">
																				Kombi:{" "}
																				<span className="text-green-400">
																					30.000
																					CASH
																				</span>
																			</li>
																			<li className="text-indigo-500">
																				Blooding
																				Racer:{" "}
																				<span className="text-green-400">
																					50.000
																					CASH
																				</span>
																			</li>
																		</ul>
																	</div>
																</>
															),
														});
													}}
												>
													Ver Benefícios
												</button>
											</div>
											<div className="w-full h-[80%] absolute top-[10%] text-center">
												<div className="w-full h-[80%] flex justify-center mt-3">
													<img
														src="https://i.imgur.com/qSGfHq4.jpg"
														className="d-block w-[70%] md:w-[50%] lg:w-[50%] xl:w-[80%] rounded-md"
														alt="..."
													/>
												</div>
											</div>
										</div>
									</div>
									<div
										className="col-md-6 col-xl-3 mt-3 flex justify-center anim-scale-left"
										style={{ animationDelay: "1.1s" }}
									>
										<div className="w-full pt-[14rem] pb-[10rem] rounded-md bg-slate-900 relative">
											<div className="absolute mt-3 w-full top-0 text-center text-white">
												<h1 className="text-sm">
													Aeronaves Raros
												</h1>
											</div>
											<div className="absolute mt-3 w-full bottom-6 text-center text-white">
												<button
													className="bg-[#3b13d1] hover:bg-indigo-600 rounded-md px-12 py-2  z-10 relative"
													onClick={() => {
														setOpenModal(true);
														setModalInfo({
															titulo: "Aeronaves Raras",
															imgSrc: "https://i.imgur.com/RxeaCvW.jpg",
															descricao: (
																<p>
																	Voe pela
																	cidade com
																	seu próprio
																	veiculo
																	raro!{" "}
																</p>
															),
															precos: (
																<>
																	<div className="w-full">
																		<h3 className="w-full">
																			Aeronaves
																			Raras:
																		</h3>
																		<ul>
																			<li className="text-red-500">
																				Maverick:{" "}
																				<span className="text-green-400">
																					50.000
																					CASH
																				</span>
																			</li>
																			<li className="text-red-500">
																				Shamal:{" "}
																				<span className="text-green-400">
																					150.000
																					CASH
																				</span>
																			</li>
																			<li className="text-red-500">
																				Raindance:{" "}
																				<span className="text-green-400">
																					65.000
																					CASH
																				</span>
																			</li>
																			<li className="text-red-500">
																				Leviatan:{" "}
																				<span className="text-green-400">
																					65.000
																					CASH
																				</span>
																			</li>
																			<li className="text-red-500">
																				Dodo:{" "}
																				<span className="text-green-400">
																					65.000
																					CASH
																				</span>
																			</li>
																		</ul>
																	</div>
																</>
															),
														});
													}}
												>
													Ver Benefícios
												</button>
											</div>
											<div className="w-full h-[80%] absolute top-[10%] text-center">
												<div className="w-full h-[80%] flex justify-center mt-3">
													<img
														src="https://i.imgur.com/RxeaCvW.jpg"
														className="d-block w-[70%] md:w-[50%] lg:w-[50%] xl:w-[80%] rounded-md"
														alt="..."
													/>
												</div>
											</div>
										</div>
									</div>
									<div
										className="col-md-6 col-xl-3 mt-3 flex justify-center anim-scale-left"
										style={{ animationDelay: "1.2s" }}
									>
										<div className="w-full pt-[14rem] pb-[10rem] rounded-md bg-slate-900 relative">
											<div className="absolute mt-3 w-full top-0 text-center text-white">
												<h1 className="text-sm">
													Armas Permanentes
												</h1>
											</div>
											<div className="absolute mt-3 w-full bottom-6 text-center text-white">
												<button
													className="bg-[#3b13d1] hover:bg-indigo-600 rounded-md px-12 py-2  z-10 relative"
													onClick={() => {
														setOpenModal(true);
														setModalInfo({
															titulo: "Armas Permanentes",
															imgSrc: "https://i.imgur.com/Kr3wyK8.png",
															descricao: (
																<p>
																	Quer ter
																	armas de um
																	modo facil e
																	rapido?
																	Compre armas
																	permanentes
																	e ande
																	sempre
																	preparado
																	pela Cidade!
																</p>
															),
															precos: (
																<>
																	<div className="w-full">
																		<h3 className="w-full">
																			Armas
																			Permanentes
																		</h3>
																		<ul>
																			<li className="text-indigo-500">
																				M4A1:{" "}
																				<span className="text-green-400">
																					50.000
																					CASH
																				</span>
																			</li>
																			<li className="text-indigo-500">
																				AK-47:{" "}
																				<span className="text-green-400">
																					50.000
																					CASH
																				</span>
																			</li>
																			<li className="text-indigo-500">
																				SNIPER:{" "}
																				<span className="text-green-400">
																					75.000
																					CASH
																				</span>
																			</li>
																			<li className="text-indigo-500">
																				SPAS12:{" "}
																				<span className="text-green-400">
																					75.000
																					CASH
																				</span>
																			</li>
																			<li className="text-indigo-500">
																				DESERT
																				EAGLE:{" "}
																				<span className="text-green-400">
																					50.000
																					CASH
																				</span>
																			</li>
																			<li className="text-indigo-500">
																				TEC-9:{" "}
																				<span className="text-green-400">
																					50.000
																					CASH
																				</span>
																			</li>
																		</ul>
																	</div>
																</>
															),
														});
													}}
												>
													Ver Benefícios
												</button>
											</div>
											<div className="w-full h-[80%] absolute top-[10%] text-center">
												<div
													id="veiculosC"
													className="carousel slide h-full"
												>
													<div className="carousel-inner">
														<div className="carousel-item active relative">
															<div className="w-full h-full flex justify-center mt-3">
																<img
																	src="https://i.imgur.com/Kr3wyK8.png"
																	className="d-block w-[70%] md:w-[50%] lg:w-[50%] xl:w-[70%] rounded-md"
																	alt="..."
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										className="col-md-6 col-xl-3 mt-3 flex justify-center anim-scale-left"
										style={{ animationDelay: "0.5s" }}
									>
										<div className="w-full pt-[14rem] pb-[10rem] rounded-md bg-slate-900 relative">
											<div className="absolute mt-3 w-full px-3 top-0 text-center text-white">
												<h1 className="text-sm">
													Troca de Nick
												</h1>
											</div>
											<div className="absolute mt-3 w-full bottom-6 text-center text-white">
												<button
													className="bg-[#3b13d1] hover:bg-indigo-600 rounded-md px-12 py-2 z-10 relative"
													onClick={() => {
														setOpenModal(true);
														setModalInfo({
															titulo: "Troca de Nick",
															imgSrc: "https://i.imgur.com/mHXgl1x.png",
															descricao: (
																<p>
																	Troque seu
																	Nick
																	instantaneamente,
																	sem perder
																	seus itens,
																	casas,
																	veiculos e
																	etc..!
																</p>
															),
															precos: (
																<ul>
																	<li className="">
																		Trocar
																		de{" "}
																		<b className="text-yellow-400">
																			Nick
																		</b>
																		:{" "}
																		<span className="text-green-400">
																			15.000
																			CASH
																		</span>
																	</li>
																</ul>
															),
														});
													}}
												>
													Ver Benefícios
												</button>
											</div>
											<div className="w-full h-[80%] absolute top-[10%] text-center">
												<div className="w-full h-[80%] flex justify-center mt-3">
													<img
														src="https://i.imgur.com/mHXgl1x.png"
														className="d-block w-[70%] md:w-[65%] lg:w-[50%] xl:w-[80%] rounded-md"
														alt="..."
													/>
												</div>
											</div>
										</div>
									</div>
									<div
										className="col-md-6 col-xl-3 mt-3 flex justify-center anim-scale-left"
										style={{ animationDelay: "0.5s" }}
									>
										<div className="w-full pt-[14rem] pb-[10rem] rounded-md bg-slate-900 relative">
											<div className="absolute mt-3 w-full px-3 top-0 text-center text-white">
												<h1 className="text-sm">
													Família
												</h1>
											</div>
											<div className="absolute mt-3 w-full bottom-6 text-center text-white">
												<button
													className="bg-[#3b13d1] hover:bg-indigo-600 rounded-md px-12 py-2 z-10 relative"
													onClick={() => {
														setOpenModal(true);
														setModalInfo({
															titulo: "Familia",
															imgSrc: "https://i.imgur.com/8S6Te0M.png",
															descricao: (
																<p>
																	<ol>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;50
																			Vagas
																			de
																			Membros
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;TAG
																			exclusiva
																			da
																			Familia
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Chat
																			exclusivo
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Comunicação
																			por
																			Rádinho
																		</li>
																		<li>
																			<i className="fas fa-check-circle text-green-400"></i>{" "}
																			&nbsp;Anúncio
																			personalizado
																			para
																			todos
																			os
																			jogadores
																		</li>
																	</ol>
																</p>
															),
															precos: (
																<ul>
																	<li className="">
																		Adquirir
																		uma{" "}
																		<b className="text-yellow-400">
																			Familia
																		</b>
																		:{" "}
																		<span className="text-green-400">
																			70.000
																			CASH
																		</span>
																	</li>
																</ul>
															),
														});
													}}
												>
													Ver Benefícios
												</button>
											</div>
											<div className="w-full h-[80%] absolute top-[10%] text-center">
												<div className="w-full h-[80%] flex justify-center mt-3">
													<img
														src="https://i.imgur.com/8S6Te0M.png"
														className="d-block w-[70%] md:w-[65%] lg:w-[50%] xl:w-[80%] rounded-md"
														alt="..."
													/>
												</div>
											</div>
										</div>
									</div>

									<div
										className="col-md-6 col-xl-3 mt-3 flex justify-center anim-scale-left"
										style={{ animationDelay: "1.2s" }}
									>
										<div className="w-full pt-[14rem] pb-[10rem] rounded-md bg-slate-900 relative">
											<div className="absolute mt-3 w-full top-0 text-center text-white">
												<h1 className="text-sm">
													Armas Permanentes
												</h1>
											</div>
											<div className="absolute mt-3 w-full bottom-6 text-center text-white">
												<button
													className="bg-[#3b13d1] hover:bg-indigo-600 rounded-md px-12 py-2  z-10 relative"
													onClick={() => {
														setOpenModal(true);
														setModalInfo({
															titulo: "Armas Permanentes",
															imgSrc: "https://i.imgur.com/Kr3wyK8.png",
															descricao: (
																<p>
																	Quer ter
																	armas de um
																	modo facil e
																	rapido?
																	Compre armas
																	permanentes
																	e ande
																	sempre
																	preparado
																	pela Cidade!
																</p>
															),
															precos: (
																<>
																	<div className="w-full">
																		<h3 className="w-full">
																			Armas
																			Permanentes
																		</h3>
																		<ul>
																			<li className="text-indigo-500">
																				M4A1:{" "}
																				<span className="text-green-400">
																					50.000
																					CASH
																				</span>
																			</li>
																			<li className="text-indigo-500">
																				AK-47:{" "}
																				<span className="text-green-400">
																					50.000
																					CASH
																				</span>
																			</li>
																			<li className="text-indigo-500">
																				SNIPER:{" "}
																				<span className="text-green-400">
																					75.000
																					CASH
																				</span>
																			</li>
																			<li className="text-indigo-500">
																				SPAS12:{" "}
																				<span className="text-green-400">
																					75.000
																					CASH
																				</span>
																			</li>
																			<li className="text-indigo-500">
																				DESERT
																				EAGLE:{" "}
																				<span className="text-green-400">
																					50.000
																					CASH
																				</span>
																			</li>
																			<li className="text-indigo-500">
																				TEC-9:{" "}
																				<span className="text-green-400">
																					50.000
																					CASH
																				</span>
																			</li>
																		</ul>
																	</div>
																</>
															),
														});
													}}
												>
													Ver Benefícios
												</button>
											</div>
											<div className="w-full h-[80%] absolute top-[10%] text-center">
												<div
													id="veiculosC"
													className="carousel slide h-full"
												>
													<div className="carousel-inner">
														<div className="carousel-item active relative">
															<div className="w-full h-full flex justify-center mt-3">
																<img
																	src="https://i.imgur.com/Kr3wyK8.png"
																	className="d-block w-[70%] md:w-[50%] lg:w-[50%] xl:w-[70%] rounded-md"
																	alt="..."
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div
										className="col-md-6 col-xl-3 mt-3 flex justify-center anim-scale-left"
										style={{ animationDelay: "1.2s" }}
									>
										<div className="w-full pt-[14rem] pb-[10rem] rounded-md bg-slate-900 relative">
											<div className="absolute mt-3 w-full top-0 text-center text-white">
												<h1 className="text-sm">
													Saia da Cadeia
												</h1>
											</div>
											<div className="absolute mt-3 w-full bottom-6 text-center text-white">
												<button
													className="bg-[#3b13d1] hover:bg-indigo-600 rounded-md px-12 py-2  z-10 relative"
													onClick={() => {
														setOpenModal(true);
														setModalInfo({
															titulo: "Saia da Cadeia",
															imgSrc: "https://i.imgur.com/wzc6Sbq.jpeg",
															descricao: (
																<p>
																	Foi preso e
																	gostaria de
																	obter a
																	liberdade
																	instantaneamente?
																	Compre isso.
																</p>
															),
															linkTuto: true,
															linkBtn:
																"https://loja.brasilmobileroleplay.com",
															precos: (
																<>
																	<div className="w-full">
																		<h3 className="w-full">
																			Sair
																			da
																			cadeia:
																		</h3>
																		<ul>
																			<li className="text-indigo-500">
																				Preço:{" "}
																				<span className="text-green-400">
																					R$
																					15,00
																				</span>
																			</li>
																		</ul>
																	</div>
																</>
															),
														});
													}}
												>
													Ver Sobre
												</button>
											</div>
											<div className="w-full h-[80%] absolute top-[10%] text-center">
												<div
													id="veiculosC"
													className="carousel slide h-full"
												>
													<div className="carousel-inner">
														<div className="carousel-item active relative">
															<div className="w-full h-full flex justify-center mt-3">
																<img
																	src="https://i.imgur.com/wzc6Sbq.jpeg"
																	className="d-block w-[70%] md:w-[50%] lg:w-[50%] xl:w-[55%] rounded-md"
																	alt="..."
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										className="col-md-6 col-xl-3 mt-3 flex justify-center anim-scale-left"
										style={{ animationDelay: "1.2s" }}
									>
										<div className="w-full pt-[14rem] pb-[10rem] rounded-md bg-slate-900 relative">
											<div className="absolute mt-3 w-full top-0 text-center text-white">
												<h1 className="text-sm">
													Desbanimento
												</h1>
											</div>
											<div className="absolute mt-3 w-full bottom-6 text-center text-white">
												<button
													className="bg-[#3b13d1] hover:bg-indigo-600 rounded-md px-12 py-2  z-10 relative"
													onClick={() => {
														setOpenModal(true);
														setModalInfo({
															titulo: "Desbanimento",
															imgSrc: "https://i.imgur.com/rW4i7p4.jpeg",
															descricao: (
																<p>
																	Foi banido
																	da cidade e
																	gostaria de
																	ter outra
																	chance?
																	Compre
																	desbanimento!
																</p>
															),
															linkBtn:
																"https://wa.me/5577999618331?text=Olá,%20quero%20pagar%20o%20meu%20desban",
															precos: (
																<>
																	<div className="w-full">
																		<h3 className="w-full">
																			Desbanimento
																		</h3>
																		<ul>
																			<li className="text-indigo-500">
																				Banimento
																				Temporário:{" "}
																				<span className="text-green-400">
																					R$
																					25,00
																				</span>
																			</li>
																			<li className="text-indigo-500">
																				Banimento
																				Permanente:{" "}
																				<span className="text-green-400">
																					R$
																					50,00
																				</span>
																			</li>
																		</ul>
																	</div>
																</>
															),
														});
													}}
												>
													Ver Sobre
												</button>
											</div>
											<div className="w-full h-[80%] absolute top-[10%] text-center">
												<div
													id="veiculosC"
													className="carousel slide h-full"
												>
													<div className="carousel-inner">
														<div className="carousel-item active relative">
															<div className="w-full h-full flex justify-center mt-3">
																<img
																	src="https://i.imgur.com/rW4i7p4.jpeg"
																	className="d-block w-[70%] md:w-[50%] lg:w-[50%] xl:w-[55%] rounded-md"
																	alt="..."
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										className="col-md-6 col-xl-3 mt-3 flex justify-center anim-scale-left"
										style={{ animationDelay: "1.2s" }}
									>
										<div className="w-full pt-[14rem] pb-[10rem] rounded-md bg-slate-900 relative">
											<div className="absolute mt-3 w-full top-0 text-center text-white">
												<h1 className="text-sm">
													Criar Empresa
												</h1>
											</div>
											<div className="absolute mt-3 w-full bottom-6 text-center text-white">
												<button
													className="bg-[#3b13d1] hover:bg-indigo-600 rounded-md px-12 py-2  z-10 relative"
													onClick={() => {
														setOpenModal(true);
														setModalInfo({
															titulo: "Criar Empresa",
															// imgSrc: "https://i.imgur.com/wzc6Sbq.jpeg",
															imgSrc2: [
																"https://i.imgur.com/BaMDPif.jpeg",
																"https://i.imgur.com/pEaOdg2.jpeg",
															],
															descricao: (
																<p>
																	Gostaria de
																	aprimorar
																	sua empresa?
																	Compre
																	carros e
																	armas!
																</p>
															),
															linkBtn:
																"https://wa.me/5577999618331?text=Olá,%20quero%20criar%20minha%20empresa",
															precos: (
																<>
																	<div className="w-full">
																		<h3 className="w-full">
																			Empresas
																			(Veiculos)
																		</h3>
																		<ul>
																			<li className="text-indigo-500">
																				Por
																				carro
																				de
																				concessionaria:{" "}
																				<span className="text-green-400">
																					R$
																					10,00
																				</span>
																			</li>
																			<li className="text-indigo-500">
																				Por
																				carro
																				de
																				emprego:{" "}
																				<span className="text-green-400">
																					R$
																					15,00
																				</span>
																			</li>
																			<li className="text-indigo-500">
																				Por
																				carro
																				fora
																				da
																				concessionaria:{" "}
																				<span className="text-green-400">
																					R$
																					20,00
																				</span>
																			</li>
																		</ul>
																		<h3 className="w-full p-2 bg-danger rounded-lg">
																			Pedido
																			minimo
																			de 5
																			carros
																		</h3>
																	</div>
																	<div className="w-full mt-2">
																		<h3 className="w-full">
																			Empresas
																			(Armas)
																		</h3>
																		<ul>
																			<li className="text-indigo-500">
																				1
																				arma:{" "}
																				<span className="text-green-400">
																					R$
																					50,00
																				</span>
																			</li>
																			<li className="text-indigo-500">
																				2
																				armas:{" "}
																				<span className="text-green-400">
																					R$
																					100,00
																				</span>
																			</li>
																			<li className="text-indigo-500">
																				3
																				armas:{" "}
																				<span className="text-green-400">
																					R$
																					150,00
																				</span>
																			</li>
																		</ul>
																	</div>
																</>
															),
														});
													}}
												>
													Ver Sobre
												</button>
											</div>

											<div
												id="carouselExample"
												class="carousel slide w-full h-[80%] absolute top-[10%] text-center"
											>
												<div class="carousel-inner">
													<div class="carousel-item active">
														<div className="w-full h-full flex justify-center mt-3">
															<img
																src="https://i.imgur.com/BaMDPif.jpeg"
																className="d-block w-[70%] md:w-[50%] lg:w-[50%] xl:w-[55%] rounded-md"
																alt="..."
															/>
														</div>
													</div>
													<div class="carousel-item">
														<div className="w-full h-full flex justify-center mt-3">
															<img
																src="https://i.imgur.com/pEaOdg2.jpeg"
																className="d-block w-[70%] md:w-[50%] lg:w-[50%] xl:w-[55%] rounded-md"
																alt="..."
															/>
														</div>
													</div>
												</div>
												<button
													class="carousel-control-prev"
													type="button"
													data-bs-target="#carouselExample"
													data-bs-slide="prev"
												>
													<span
														class="carousel-control-prev-icon"
														aria-hidden="true"
													></span>
													<span class="visually-hidden">
														Previous
													</span>
												</button>
												<button
													class="carousel-control-next"
													type="button"
													data-bs-target="#carouselExample"
													data-bs-slide="next"
												>
													<span
														class="carousel-control-next-icon"
														aria-hidden="true"
													></span>
													<span class="visually-hidden">
														Next
													</span>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</main>
				</main>
			</div>
			{openModal ? (
				<Modal
					show={openModal}
					size="5xl"
					className="anim-scale-reverse"
					onClose={() => setOpenModal(false)}
				>
					<Modal.Body className="bg-slate-900  rounded-t-lg pb-0 relative">
						<div className="hidden md:block">
							<button
								className="absolute right-3 top-3 text-white py-3 px-12 rounded-md hover:text-gray-200"
								onClick={() => setOpenModal(false)}
							>
								<i className="fas fa-times text-2xl"></i>
							</button>
							<div className="text-center w-full text-white">
								<h3 style={{ fontFamily: "Poppins" }}>
									{modalInfo.titulo}
								</h3>
							</div>
							<div
								className="py-12 inline-flex space-x-5 text-white"
								style={{ fontFamily: "Poppins" }}
							>
								<div className="w-[50%]  px-2 py-2">
									{modalInfo.imgSrc2 == null ? (
										<img
											src={modalInfo.imgSrc}
											className="rounded-lg"
											alt=""
											srcSet=""
											width="100%"
										/>
									) : (
										<div
											id="carouselExample2"
											class="carousel slide w-full text-center"
										>
											<div class="carousel-inner">
												<div class="carousel-item active">
													<div className="w-full h-full flex justify-center mt-3">
														<img
															src="https://i.imgur.com/pEaOdg2.jpeg"
															className="d-block w-75 rounded-md"
															alt="..."
														/>
													</div>
												</div>
												<div class="carousel-item">
													<div className="w-full h-full flex justify-center mt-3">
														<img
															src="https://i.imgur.com/BaMDPif.jpeg"
															className="d-block w-75 rounded-md"
															alt="..."
														/>
													</div>
												</div>
											</div>
											<button
												class="carousel-control-prev"
												type="button"
												data-bs-target="#carouselExample2"
												data-bs-slide="prev"
											>
												<span
													class="carousel-control-prev-icon"
													aria-hidden="true"
												></span>
												<span class="visually-hidden">
													Previous
												</span>
											</button>
											<button
												class="carousel-control-next"
												type="button"
												data-bs-target="#carouselExample2"
												data-bs-slide="next"
											>
												<span
													class="carousel-control-next-icon"
													aria-hidden="true"
												></span>
												<span class="visually-hidden">
													Next
												</span>
											</button>
										</div>
									)}
								</div>
								<div className="w-[50%] px-2 py-2 inline-flex flex-col space-y-6">
									{modalInfo.descricao}

									<div
										className="w-full"
										style={{ fontFamily: "Poppins" }}
									>
										{modalInfo.precos}
									</div>
									{modalInfo.linkBtn != null ? (
										<>
											<div className="w-full  space-x-6">
												<button
													className="bg-green-500 w-full text-white py-3 px-12 rounded-md"
													onClick={() =>
														(window.location.href =
															modalInfo.linkBtn)
													}
												>
													<i className="fal fa-shopping-cart text-xl"></i>
													&nbsp; Comprar{" "}
												</button>
												{modalInfo.linkTuto != null ? (
													<>
														<div
															className="w-full flex justify-start items-start space-x-0 mt-3"
															style={{
																margin: 0,
															}}
														>
															<button
																onClick={() =>
																	(window.location.href =
																		"https://youtu.be/XxN_SjCa5PY?si=lxQzxtyq9tLnvRO_")
																}
																className="bg-[#e3ff42] w-full text-black py-3 px-12 rounded-md"
															>
																&nbsp; Como
																comprar cash{" "}
																<i class="fas fa-external-link-alt"></i>
															</button>
														</div>
													</>
												) : (
													""
												)}
											</div>
										</>
									) : (
										<>
											<div className="w-full  space-x-6">
												<button
													className="bg-green-500 w-full text-white py-3 px-12 rounded-md"
													onClick={() =>
														(window.location.href =
															"https://loja.brasilmobileroleplay.com")
													}
												>
													<i className="fal fa-shopping-cart text-xl"></i>
													&nbsp; Comprar{" "}
												</button>
											</div>
											<div className="w-full space-x-6">
												<button
													onClick={() =>
														(window.location.href =
															"https://youtu.be/XxN_SjCa5PY?si=lxQzxtyq9tLnvRO_")
													}
													className="bg-[#e3ff42] w-full text-black py-3 px-12 rounded-md"
												>
													&nbsp; Como comprar cash{" "}
													<i class="fas fa-external-link-alt"></i>
												</button>
											</div>
										</>
									)}
								</div>
							</div>
						</div>
						<div className="block md:hidden ">
							<button
								className="absolute right-3 top-3 text-white py-3 px-12 rounded-md hover:text-gray-200"
								onClick={() => setOpenModal(false)}
							>
								<i className="fas fa-times text-2xl"></i>
							</button>
							<div className="w-full h-full">
								<div className="text-center w-full text-white">
									<h3 style={{ fontFamily: "Poppins" }}>
										{modalInfo.titulo}
									</h3>
								</div>
								<div
									className="py-12 space-x-5 text-white"
									style={{ fontFamily: "Poppins" }}
								>
									<div className="w-full  px-2 py-2">
										{modalInfo.imgSrc2 == null ? (
											<img
												src={modalInfo.imgSrc}
												className="rounded-lg"
												alt=""
												srcSet=""
												width="100%"
											/>
										) : (
											<div
												id="carouselExample3"
												class="carousel slide w-full text-center"
											>
												<div class="carousel-inner">
													<div class="carousel-item active">
														<div className="w-full h-full flex justify-center mt-3">
															<img
																src="https://i.imgur.com/BaMDPif.jpeg"
																className="d-block w-75 rounded-md"
																alt="..."
															/>
														</div>
													</div>
													<div class="carousel-item">
														<div className="w-full h-full flex justify-center mt-3">
															<img
																src="https://i.imgur.com/pEaOdg2.jpeg"
																className="d-block w-75 rounded-md"
																alt="..."
															/>
														</div>
													</div>
												</div>
												<button
													class="carousel-control-prev"
													type="button"
													data-bs-target="#carouselExample3"
													data-bs-slide="prev"
												>
													<span
														class="carousel-control-prev-icon"
														aria-hidden="true"
													></span>
													<span class="visually-hidden">
														Previous
													</span>
												</button>
												<button
													class="carousel-control-next"
													type="button"
													data-bs-target="#carouselExample3"
													data-bs-slide="next"
												>
													<span
														class="carousel-control-next-icon"
														aria-hidden="true"
													></span>
													<span class="visually-hidden">
														Next
													</span>
												</button>
											</div>
										)}
									</div>
									<div className="w-full ms-0 py-2 inline-flex flex-col space-y-6">
										{modalInfo.descricao}

										<div
											className="w-full"
											style={{
												fontFamily: "Poppins",
											}}
										>
											{modalInfo.precos}
										</div>

										{modalInfo.linkBtn != null ? (
											<>
												<div className="w-full  space-x-6">
													<button
														className="bg-green-500 w-full text-white py-3 px-12 rounded-md"
														onClick={() =>
															(window.location.href =
																modalInfo.linkBtn)
														}
													>
														<i className="fal fa-shopping-cart text-xl"></i>
														&nbsp; Comprar{" "}
													</button>
													{modalInfo.linkTuto !=
													null ? (
														<>
															<div
																className="w-full flex justify-start items-start space-x-0 mt-3"
																style={{
																	margin: 0,
																}}
															>
																<button
																	onClick={() =>
																		(window.location.href =
																			"https://youtu.be/XxN_SjCa5PY?si=lxQzxtyq9tLnvRO_")
																	}
																	className="bg-[#e3ff42] w-full text-black py-3 px-12 rounded-md"
																>
																	&nbsp; Como
																	comprar cash{" "}
																	<i class="fas fa-external-link-alt"></i>
																</button>
															</div>
														</>
													) : (
														""
													)}
												</div>
											</>
										) : (
											<>
												<div className="w-full  space-x-6">
													<button
														className="bg-green-500 w-full text-white py-3 px-12 rounded-md"
														onClick={() =>
															(window.location.href =
																"https://loja.brasilmobileroleplay.com")
														}
													>
														<i className="fal fa-shopping-cart text-xl"></i>
														&nbsp; Comprar{" "}
													</button>
												</div>
												<div className="w-full space-x-6">
													<button
														onClick={() =>
															(window.location.href =
																"https://youtu.be/XxN_SjCa5PY?si=lxQzxtyq9tLnvRO_")
														}
														className="bg-[#e3ff42] w-full text-black py-3 px-12 rounded-md"
													>
														&nbsp; Como comprar cash{" "}
														<i class="fas fa-external-link-alt"></i>
													</button>
												</div>
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</Modal.Body>
				</Modal>
			) : (
				""
			)}
		</>
	);
};

export default Catalogo;
