import React from "react";
import { Link } from "react-router-dom";
const Navbar = ({ type }) => {
	console.log(type);
	function typeF() {
		if (type == 1) {
			return (
				<>
					<li className={`nav-item px-3 bg-white rounded-lg`}>
						<a className="nav-link text-black" href="/">
							<i className="far fa-home"></i>&nbsp; Pagina Inícial
						</a>
					</li>
					<li className={`nav-item px-3`}>
						<a className="nav-link" href="/catalogo">
							<i className="fal fa-shopping-cart"></i> Catalogo
						</a>
					</li>
					<li className={`nav-item px-3 rounded-lg text-white`}>
						<a
							className="nav-link"
							href="https://loja.brasilmobileroleplay.com"
							target="_blank"
							rel="noreferrer"
						>
							<i className="fas fa-store"></i>&nbsp; Loja de CASH
						</a>
					</li>
				</>
			);
		} else if (type == 2) {
			return (
				<div>
					<li className={`nav-item px-3 rounded-lg text-white`}>
						<a className="nav-link" href="/">
							<i className="far fa-home"></i>&nbsp; Pagina Inícial
						</a>
					</li>
					<li className={`nav-item px-3 bg-white rounded-lg`}>
						<a className="nav-link text-black" href="/catalogo">
							<i className="fal fa-shopping-cart"></i> Catalogo
						</a>
					</li>
					<li className={`nav-item px-3 rounded-lg text-white`}>
						<a
							className="nav-link"
							href="https://loja.brasilmobileroleplay.com"
							target="_blank"
							rel="noreferrer"
						>
							<i className="fas fa-store"></i>&nbsp; Loja de CASH
						</a>
					</li>
				</div>
			);
		}
	}

	return (
		<>
			<div
				className="offcanvas offcanvas-end shadow rounded "
				tabIndex="-1"
				id="offcanvasMainNavbar"
				aria-labelledby="offcanvasMainNavbarLabel"
				style={{ fontFamily: "Poppins" }}
			>
				<div className="offcanvas-header bg-red-500">
					<h5
						className="offcanvas-title text-white"
						id="offcanvasMainNavbarLabel"
						style={{ fontFamily: "Poppins" }}
					>
						Menu
					</h5>
					<button
						className="btn-close btn-close-white"
						type="button"
						aria-label="Fechar"
						data-bs-dismiss="offcanvas"
					>
						<i className="fas fa-times text-lg text-white"></i>
					</button>
				</div>
				<div className="offcanvas-body bg-red-500 text-white">
					<ul className="navbar-nav flex-grow-1 ms-2 fs-6 space-y-3">
						{typeF()}
					</ul>
				</div>
			</div>
			<button
				// to="/"
				className="absolute top-5 cursor-pointer rounded-lg z-10 text-white right-4 px-3 py-2 border-[4px] border-white"
				data-bs-toggle="offcanvas"
				data-bs-target="#offcanvasMainNavbar"
			>
				<i className="far fa-bars text-2xl text-white"></i>
			</button>
		</>
	);
};
export default Navbar;
