import React, { useState } from "react";
import "./HomePage.css";
import style from "../App.module.css";
import vdo from "../assets/backgroundsite.mp4";

import Navbar from "./Navbar";

import Swal from "sweetalert2/dist/sweetalert2.js";
let link_launcher =
	"https://github.com/zyurifarias/data/raw/main/BrasilMobileRoleplay.apk?download=";
let link_tutorial = "https://www.youtube.com/watch?v=xJddp0Hpoo4";
const HomePage = () => {
	return (
		<>
			<div className="overflow-hidden">
				<div
					className="w-screen h-screen flex justify-center items-center bg-no-repeat bg-cover"
					style={{ fontFamily: "Poppins" }}
				>
					<Navbar type="1" />
					<video id="background-video" autoPlay muted loop>
						<source src={vdo} type="video/mp4" autoPlay />
					</video>
					<main className="w-screen relative h-[80%] flex justify-center mb-16">
						<img
							src="https://i.imgur.com/62gXk3G.png"
							className="App-logo absolute"
							alt="logo"
							width={150}
							height={150}
						/>
						<h1
							className={` text-white text-2xl absolute top-[30%] anim-scale`}
						>
							Brasil Mobile Roleplay
						</h1>
						<div className="w-full absolute top-[37%] text-center anim-scale">
							<h1 className={` text-white text-sm `}>
								Conecte-se com seus amigos através do{" "}
								<b>Brasil Mobile Roleplay!</b>
							</h1>
						</div>
						<div className="w-full absolute top-[48%] text-center anim-scale">
							<a
								href={link_launcher}
								target="_blank"
								rel="noreferrer"
								className={`bg-red-500 px-12 py-3 ${style.pulse} text-white rounded-lg ${style.bShadow} inline-flex space-x-3 justify-center items-center`}
							>
								<i className="fas fa-download"></i>
								<span>BAIXAR GTA RP - MOBILE</span>
							</a>
						</div>
						<div className="w-full absolute top-[60%] text-center anim-scale">
							<a
								href={link_tutorial}
								target="_blank"
								rel="noreferrer"
								className={`bg-blue-500 px-10 py-3 text-white ${style.b2Shadow} rounded-lg inline-flex space-x-3 justify-center items-center`}
							>
								<i className="fas fa-video"></i>
								<span>TUTORIAL DE INSTALAÇÃO</span>
							</a>
						</div>
						<div className="w-full absolute top-[72%] text-center anim-scale">
							<div className="w-full flex justify-center">
								<p className="w-[70%] lg:w-[30%] text-white m-3">
									Caso seja iniciante e quer saber como trocar
									de roupa, comprar veículos e diversas outras
									coisas acesse nosso guia:
								</p>
							</div>
							<a
								href="https://bit.ly/guiaparainiciantesbmrp"
								target="_blank"
								rel="noreferrer"
								className={`bg-green-500 px-10 py-3 text-white border-none rounded-lg inline-flex space-x-3 justify-center items-center`}
							>
								<i className="fas fa-video"></i>
								<span>GUIA PARA INICIANTESㅤ</span>
							</a>
						</div>
						<div className="w-full absolute top-[97%] hidden  space-x-3 justify-center items-center text-3xl text-white">
							<a
								href="https://www.tiktok.com/@brasilmobileroleplay"
								target="_blank"
								rel="noreferrer"
								className="rounded-xl py-1 px-3 text-white transition-all hover:opacity-80"
							>
								<i className="fab fa-tiktok"></i>
							</a>
							<a
								href="https://www.instagram.com/bmrpoficial"
								target="_blank"
								rel="noreferrer"
								className="rounded-xl py-1 px-3 text-white transition-all hover:opacity-80"
							>
								<i className="fab fa-instagram"></i>
							</a>
							<a
								href="https://discord.gg/bmrp"
								target="_blank"
								rel="noreferrer"
								className="rounded-xl py-1 px-3 text-white transition-all hover:opacity-80"
							>
								<i className="fab fa-discord"></i>
							</a>
							<a
								href="https://www.youtube.com/@bmrpoficial"
								target="_blank"
								rel="noreferrer"
								className="rounded-xl py-1 px-3 text-whitetransition-all hover:opacity-80"
							>
								<i className="fab fa-youtube"></i>
							</a>
						</div>
						<div className="w-full hidden absolute bottom-[-15%] text-center">
							<h1 className={` text-white`}>BMRP &copy; 2023</h1>
						</div>
					</main>
				</div>
			</div>
		</>
	);
};

export default HomePage;
