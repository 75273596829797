import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { BrowserRouter, Router, Route, Routes } from "react-router-dom";
import Catalogo from "./components/Catalogo";
import HomePage from "./components/HomePage";
import Promocao from "./components/Promocao";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<HomePage />}></Route>
				<Route path="/catalogo" element={<Catalogo />}></Route>
				<Route path="/promocao" element={<Promocao />}></Route>
			</Routes>
		</BrowserRouter>
	</React.StrictMode>
);
